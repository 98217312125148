import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import HeroSectionServices from '../HeroServices/HeroSectionServices';
import SectorMainDisplay from '../SectorsCards/SectorMainDisplay';



export default function Services() {
  return (
    <div>
    <SectorMainDisplay/>
    <Footer />
    </div>
    
  )
}
