import React from 'react';
import '../App.css';

import ButtonEnergy from './Button_Energy/ButtonEnergy';
import './HeroSection.css';
import logo from './logo.png'
import Backdrop from './backdrop.jpg'

function HeroSection() {
  return (
    <div className='hero-container'>
      <img className='backdrop' src={Backdrop}/>
      <div className='hero-container-2'>
      <img src={logo} alt='logo' className='logo_hero'/>
      <h1 className='Hero_Heading'>ADORABLE HEALTH AND SAFETY</h1>
      <div className='Hero_Heading_2'>Elevating patient care with top-quality medical equipment.</div>
      

      </div>
    </div>
  );
}

export default HeroSection;
