

const DataForSectors = [
    {
        id: '1',
        title: 'Medical Wholesalers',
        img: 'images/medicalwholesales.jpg'
    },
    {
        id: '2',
        title: 'Medical Practitioners',
        img: 'images/MedicalPractitioner.jpg'
    },
    {
        id: '8',
        title: 'Medical Distribution Planning',
        img: 'images/Distribution.jpg'
    },
    {
        id: '3',
        title: 'Hospitals & Clinics',
        img: 'images/hospitalsckinics.jpg'
    },
    {
        id: '7',
        title: 'Medical Equipment Consulting',
        img: 'images/medicalequipment.jpg'
    },
    {
        id: '4',
        title: 'Government Departments',
        img: 'images/Departments.jpg'
    },
    {
        id: '5',
        title: 'Emergency Services',
        img: 'images/EmergencyServices.jpg'
    },
    {
        id: '6',
        title: 'Veterinary Services',
        img: 'images/VetServices.jpg'
    },





    
    
    ]
    
    export default DataForSectors