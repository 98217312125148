import React from 'react'
import './Contactus.css'

function ContactUs() {
    return (
        <div className='contactus_wrapper'>
            <div className='contactus_text_area'>
                <div className='heading_contactus'>
                    CONTACT US
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Director
                    </div>
                    <div className='large_text'>
                    
                    Mamello Adorable Daye
                    </div>
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Phone Number
                    </div>
                    <div className='large_text'>
                    082 926 2721
                    </div>
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Email Address
                    </div>
                    <div className='large_text'>
                    AdorableHealthandSafety@gmail.com
                    </div>
                </div>

                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Physical Address
                    </div>
                    <div className='large_text'>
                    4 Koorsboom ave, Glen Marais, Kempton Park
                    </div>
                </div>


            </div>
        </div>
    )
}

export default ContactUs