import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import InfoCardMain from '../ShortInfoPanel/InfoCardMain';
import SectorMainDisplay from '../SectorsCards/SectorMainDisplay';
import ContactUs from '../Contactus/ContactUs';




function Home() {
  return (
    <>
      <HeroSection />
      <InfoCardMain/>
      <SectorMainDisplay/>
      <ContactUs/>
      <Footer />
      
    </>
  );
}

export default Home;
