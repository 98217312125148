import React from "react";
import ButtonEnergy from "../Button_Energy/ButtonEnergy";
import "./InfoPanel.css";



function InfoPanel(props) {
  return (

      <div className="blog-post">
        <div className="blog-post__img">
          <img src={props.img} alt="" />
        </div>
        <div className="blog-post__info">
          <div className="blog-post__date" />

          <h1>{props.title}</h1>
          <p>{props.desc}</p>
          
        </div>
      </div>
    
  );
}

export default InfoPanel;
