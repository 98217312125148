import React from 'react'
import './SectorCards.css'

function SectorCard(props) {
  return (
    <>
        <div className='sector_wrapper'>
            <div className='sector_image'>
            <img src={props.img} alt='some logo'/>
            </div>
            <div className='text_area'>
                <div className='title_sector'>
                    {props.title}
                </div>
            </div>
        </div>

    </>
  )
}

export default SectorCard