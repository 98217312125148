import React from "react";
import "../../App.css";
import ContactUs from "../Contactus/ContactUs";
import Footer from "../Footer";



function Contactus() {
    return (
        <div>

            <ContactUs />
            <Footer />
            
        </div>
    )
}

export default Contactus